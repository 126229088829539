// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../node_modules/postcss-loader/src/index.js??postcss!./tailwind.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../node_modules/postcss-loader/src/index.js??postcss!./sweetsans.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
exports.push([module.id, "html,\nbody {\n  background-color: #F2F2F2;\n}\n\n* {\n  font-family: \"Sweet Sans Pro\";\n}\n\nbutton:disabled {\n  cursor: not-allowed;\n}\n\n.rounded-100 {\n  border-radius: 100px;\n}\n\n.hide-scroll {\n  -ms-overflow-style: none;\n  scrollbar-width: none;\n}\n\n.hide-scroll::-webkit-scrollbar {\n  display: none;\n}\n\n.figma-shadow {\n  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);\n}\n\ninput,\ntextarea,\nselect {\n  appearance: none;\n  -webkit-appearance: none;\n  border-radius: 0;\n}\n\ntextarea:focus,\ninput:focus,\nbutton:focus {\n  outline: none;\n}\n\n.max-h-screen-2 {\n  max-height: 200vh;\n}\n\n.pt-18 {\n  padding-top: 4.25rem;\n}\n\n.bottom-4\\.5 {\n  bottom: 1.125rem;\n}\n\n.top-21 {\n  top: 5.25rem;\n}\n\n/* Animation */\n\n@keyframes Gradient {\n  0% {\n    background-position: 0% 50%\n  }\n  50% {\n    background-position: 100% 50%\n  }\n  100% {\n    background-position: 0% 50%\n  }\n}\n\n.nectar-gradient-border {\n  background: linear-gradient(to right, #1F1125, #1F1125),\n    linear-gradient(to right, #F23900 0, #E2A8C6 50%, #F2F2F2 100%);\n  background-clip: padding-box, border-box;\n  background-origin: padding-box, border-box;\n  border: 2px solid transparent;\n}\n\n.nectar-results-share-gradient-border {\n  background: linear-gradient(to right, #F2F2F2, #F2F2F2),\n    linear-gradient(to right, #1F1125 0, #F23900 50%, #E2A8C6 100%);\n  background-clip: padding-box, border-box;\n  background-origin: padding-box, border-box;\n  border: 2.5px solid transparent;\n}\n\n.magnifying-glass {\n  border-color: #F23900;\n}\n\niframe {\n  display: none;\n}\n\ninput::-webkit-slider-thumb {\n  appearance: none;\n  width: 6px;\n  height: 12px;\n  background: #F23900;\n  cursor: pointer;\n}\n\ndiv::-webkit-scrollbar {\n  width: 0;\n  height: 0;\n}\n", ""]);
// Exports
module.exports = exports;
